import { useEffect } from "react";
import { useAtom } from "jotai";
import { isLoadedAtom } from "../atoms";
import { appLocalStore, appNoteStore, enableDebug } from "../services";
import { useIsPageVisible } from "../../utils/useIsPageVisible";

import { Env, defaultDevEnableDebug, env, isRunningInIOSWebview, modelVersion } from "../../utils/environment";
import { useAuth } from "../../auth/useAuth";
import { updateSyncStats } from "./SyncStats";

// Usually we keep this short to ensure fast load time at the cost of maybe
// loading twice. But on iOS webview, memory is limited so the cost of multiple
// loads is too high, so we increase the interval to reduce chance of loading
// twice.
const loadIntervalMs = isRunningInIOSWebview ? 20_000 : 1_000;

export const useSyncNotes = (isSyncWorkerReady: boolean) => {
  const isPageVisible = useIsPageVisible();
  const { user } = useAuth();
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);

  // Load all notes right at the beginning, and keep trying until we get a
  // a successful response. If we're stuck in this state, eventually the sync
  // indicator will raise an error, prompting the user to refresh the page.
  useEffect(() => {
    if (!isSyncWorkerReady) return;
    if (isLoaded) return;
    appLocalStore.loadAllData(modelVersion);
    const loadInterval = setInterval(() => appLocalStore.loadAllData(modelVersion), loadIntervalMs);

    // Set debug settings from local storage. If none is set, we default to
    // "*" in develop and preview branches so we can capture all logs in
    // sentry, but don't in prod to avoid spamming the user/sentry.
    const enableAll = env === Env.preview || env === Env.dev;
    enableDebug(localStorage.getItem("debug") ?? (defaultDevEnableDebug || (enableAll ? "*" : "")));

    return () => clearInterval(loadInterval);
  }, [isSyncWorkerReady, setIsLoaded, isLoaded]);

  // sync on interval
  useEffect(() => {
    if (!isSyncWorkerReady || !isLoaded) return;
    // NB: hooks are instantiated per component, so this setInterval will run once per component as well
    // Immediately attempt the first sync without waiting the 5s
    if (isPageVisible) {
      appLocalStore.syncAllData(modelVersion);
    }
    const inter = setInterval(() => appLocalStore.syncAllData(modelVersion), isPageVisible ? 5_000 : 120_000);
    return () => clearInterval(inter);
  }, [isSyncWorkerReady, isPageVisible, isLoaded]);

  // sync on focus
  useEffect(() => {
    if (!isSyncWorkerReady || !isLoaded) return;
    const onFocus = () => appLocalStore.syncAllData(modelVersion);
    window?.addEventListener("focus", onFocus);
    return () => window?.removeEventListener("focus", onFocus);
  }, [isSyncWorkerReady, isLoaded]);

  // Hook into the local store and update the sync stats atom whenever the
  // local store receives a sync stats update from the service worker.
  useEffect(() => {
    appLocalStore.onSyncStatsUpdate = (update) => {
      updateSyncStats({ ...update, client: { ...appNoteStore.getStats(), userId: user?.id ?? "" } });
    };
    return () => {
      appLocalStore.onSyncStatsUpdate = () => {};
    };
  }, [user]);
};
