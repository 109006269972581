import { useCallback, useEffect, useRef } from "react";
import { captureEvent } from "@sentry/nextjs";
import { getDefaultStore } from "jotai";
import { useAuth } from "../auth/useAuth";
import { isRunningInIOSWebview } from "../utils/environment";
import { iosPhysicalMemoryAtom } from "../model/atoms";
import { trackEvent } from "./analyticsHandlers";

export default function useTrackMemory() {
  const FREQUENCY_IN_MS = 20000;
  const { user } = useAuth();
  const timeoutId = useRef<null | NodeJS.Timer>(null);
  const sentryEventDispatchedOnce = useRef(false);

  const trackHeapUsage = useCallback(async () => {
    if (!user || user.email === null) {
      timeoutId.current = setTimeout(trackHeapUsage, FREQUENCY_IN_MS);
      return;
    }
    // eslint-disable-next-line
    // @ts-ignore
    const isMemoryStatsAvailable = !!(window.performance.memory && window.performance.memory.jsHeapSizeLimit);
    {
      const payload = {
        // eslint-disable-next-line
        // @ts-ignore
        deviceMemory: navigator.deviceMemory, //Not available on Safari, FireFox
        memory: {
          // eslint-disable-next-line
          // @ts-ignore
          jsHeapSizeLimit: isMemoryStatsAvailable ? window.performance.memory.jsHeapSizeLimit : null,
          // eslint-disable-next-line
          // @ts-ignore
          totalJSHeapSize: isMemoryStatsAvailable ? window.performance.memory.totalJSHeapSize : null,
          // eslint-disable-next-line
          // @ts-ignore
          usedJSHeapSize: isMemoryStatsAvailable ? window.performance.memory.usedJSHeapSize : null,
          iosPhysicalMemory: isRunningInIOSWebview ? getDefaultStore().get(iosPhysicalMemoryAtom) : null,
        }, //Deprecated. Not available on Safari, FireFox.
        eventCounts: {
          keyup: window.performance.eventCounts ? window.performance.eventCounts.get("keyup") : null,
          keydown: window.performance.eventCounts ? window.performance.eventCounts.get("keydown") : null,
          click: window.performance.eventCounts ? window.performance.eventCounts.get("click") : null,
          keypress: window.performance.eventCounts ? window.performance.eventCounts.get("keypress") : null,
        },
        storage: navigator?.storage?.estimate ? await navigator.storage.estimate() : null,
        visibleTextLength: document.getElementsByClassName("editor-div")[0]?.textContent?.length,
        visibleNotes: document.getElementsByClassName("note").length,
      };
      trackEvent("heap_memory_usage", JSON.stringify(payload));
      if (
        isMemoryStatsAvailable &&
        // eslint-disable-next-line
        // @ts-ignore
        window.performance.memory.usedJSHeapSize / (1024 * 1024) > 500 &&
        !sentryEventDispatchedOnce.current
      ) {
        captureEvent({
          message: `Memory exceeded 500MB for user ${user.email}.`,
          tags: { category: "heap_memory_usage" },
          level: "warning",
          fingerprint: ["heap_memory_usage", user.email],
        });
        sentryEventDispatchedOnce.current = true;
      }
      timeoutId.current = setTimeout(trackHeapUsage, FREQUENCY_IN_MS);
    }
  }, [user]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    trackHeapUsage();
    return () => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [trackHeapUsage]);
}
